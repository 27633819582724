// FIXME this probably belongs in tailwind.config?

const panelStyles = {
    basic: 'px-6 py-8 bg-white flex flex-col max-xs:grow',
    basicXs: 'xs:mt-4 xs:rounded-[40px] xs:h-full xs:w-full',
    basicMd: 'md:px-10 md:py-10 md:max-w-[90%] md:w-[840px] md:mx-auto',
  }
  
  const continueButton = {
    base: 'bg-blurple py-2 rounded-[40px]  w-[300px]  h-[50px] flex items-center justify-center text-white text-[16px] font-[600] cursor-pointer border-none transition-all duration-200',
    xs: 'xs:hover:bg-[#0D2AE7] xs:rounded-[8px] xs:w-[147px]',
  }
  
  const styles = {
    breakpoint: {
      // this is here so JS code can reference it -- should match value in
      // tailwind.config.js
      // TODO: just remove this, and change all classes from 'xs' to 'sm'
      xs: 640,
    },
    button: {
      basic:
        'px-3 py-2 rounded-[8px] border-[1px] enabled:hover:border-[#6172F3] transition-all disabled:opacity-50',
      continue: `${continueButton.base} ${continueButton.xs}`,
      decision:
        'bg-blurple hover:bg-[#0D2AE7] bg-[length:400px] py-2 px-[80px] rounded-[8px] flex items-center justify-center text-white cursor-pointer border-none transition-all duration-200',
      fancy:
        'bg-blurple hover:bg-[url("https://app.thyself.ai/images/background.jpg")] bg-[length:400px] py-2 rounded-[8px] flex items-center justify-center text-white cursor-pointer border-none transition-all duration-200',
      white: 'px-12 py-4 rounded-[8px] text-lg bg-white font-[600]',
      cancel:
        'px-3 py-2 rounded-[8px] bg-[#D92D20] text-white disabled:opacity-50',
    },
    panel: {
      overlay:
        'px-6 md:px-10 py-8 md:py-10 mt-4 max-w-[506px] h-[529px] md:mx-auto rounded-[40px] bg-white',
      basic: `${panelStyles.basic} ${panelStyles.basicXs} ${panelStyles.basicMd}`,
    },
    text: {
      pageTitle:
        'text-3xl md:text-5xl font-satoshi-bold text-textblack text-center',
      sectionHeader: 'text-2xl md:text-3xl font-satoshi text-textblack',
      h1: '',
      h2: 'text-3xl md:text-5xl font-satoshi-bold text-textblack md:leading-[44px]',
      h3: 'text-[18px] md:text-[20px] font-satoshi text-textgray',
      cardTitle: 'text-lg xs:text-xl font-satoshi text-textblack',
      bulletCopy: 'text-1 sm:text-[20px] font-[400] text-[#333] leading-[24px]',
      copy: 'text-[18px] font-[400] text-textgray leading-[20px]',
    },
  }
  
  export default styles
  