import { Buffer } from 'buffer'
import type {
  AudioEncoding,
  ConversationStatus,
  StopMessage,
  SelfHostedConversationConfig,
} from 'vocode'
import { stringify } from 'vocode/dist/utils'
import Recorder, { SAMPLING_RATE } from './recorder'
import {
  createWebSocket,
  getAudioConfigStartMessage,
  markTime,
  VAD_REALLY_SPEAKING_DELAY_MS,
} from './util'

const MUTE_MIC_WHEN_SPEAKING = true
const DELAY_LISTENING_MS = 1000
const DELAY_UNMUTE_MS = 500

export type Config = SelfHostedConversationConfig & {
  metadata: {
    accessToken: string
    refreshToken: string
    prompt: string
    resume: boolean
    initialMessageType?: string
  }
  enableDoneButton: boolean
}

type ControlMessageOp = 'defer_responding' | 'test' | 'interrupt'

// during hot-reloading, we might orphan a live Conversation instance--use this
// to clean up
let singleton: Conversation | undefined

export class Conversation {
  public audioContext: AudioContext
  public analyser: AnalyserNode
  public statusCallback?: (status: any) => void
  public errorCallback?: (error: any) => void
  public thinkingCallback?: (thinking: boolean) => void
  public talkingCallback?: (talking: boolean) => void
  public transcriptCallback?: (text: string) => void
  public socketMessageCallback?: (message: any) => void
  public status: ConversationStatus
  public recorder?: Recorder
  public processing: boolean

  private socket?: WebSocket
  private vad?: any
  private config: Config
  private audioQueue: any[]
  private thinking: boolean
  private playingBuffer?: AudioBufferSourceNode
  private micStream?: MediaStream
  private delayListeningTimeout?: NodeJS.Timeout
  private muteMic: boolean = false
  private unmuteTimeout: any
  private runOnConnect?: () => void
  private paused: boolean
  private soundEffects?: Record<string, AudioBuffer>
  private responseFailureTimeout?: NodeJS.Timeout

  constructor(config: Config) {
    if (singleton) singleton.stop()
    this.config = config
    this.audioContext = new AudioContext()
    this.analyser = this.audioContext.createAnalyser()
    this.audioQueue = []
    this.thinking = false
    this.status = 'idle'
    this.paused = false
    this.processing = false
    singleton = this

     if ((window as any).myself) {
    (window as any).myself.conversation = this
  } else {
    console.warn("'myself' object not found on window. Conversation instance not set.");
    (window as any).myself = {
      conversation: this
    }
  }
  
  }

  async start(options: {
    resume: boolean
    initialAudioURL?: string
    reconnecting?: boolean
  }) {
    if (!this.soundEffects) {
      this.soundEffects = {}
      const resp = await fetch('https://app.thyself.ai/audio/confirmed-minimal.mp3')
      const buffer = await resp.arrayBuffer()
      const audioData = await this.audioContext.decodeAudioData(buffer)
      this.soundEffects['done'] = audioData
    }
    
    try {
      this.setStatus('connecting')
      try {
        this.socket = await createWebSocket(this.config.backendUrl)
      } catch (err: any) {
        this.stop(err)
        return
      }

      this.setError(undefined)

      this.socket.addEventListener('message', this.onMessageHandler)
      this.socket.addEventListener('close', this.onCloseHandler)

      // wait for socket to be ready
      await new Promise(resolve => {
        const interval = setInterval(() => {
          if (this.socket!.readyState === WebSocket.OPEN) {
            clearInterval(interval)
            resolve(null)
          }
        }, 100)
      })

      const config = this.config
      try {
        const trackConstraints: MediaTrackConstraints = {
          echoCancellation: true,
          sampleRate: SAMPLING_RATE,
        }
        if (config.audioDeviceConfig.inputDeviceId) {
          console.log(
            'Using input device',
            config.audioDeviceConfig.inputDeviceId
          )
          trackConstraints.deviceId = config.audioDeviceConfig.inputDeviceId
        }
        this.micStream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: trackConstraints,
        })
      } catch (error) {
        if (error instanceof DOMException && error.name === 'NotAllowedError') {
          alert('You need to grant access to the microphone to continue.')
          error = new Error('Microphone access denied')
        }
        console.error(error)
        this.stop(error as Error)
        return false
      }
      const micSettings = this.micStream.getAudioTracks()[0].getSettings()
      const inputAudioMetadata = {
        samplingRate: micSettings.sampleRate || this.audioContext.sampleRate,
        audioEncoding: 'linear16' as AudioEncoding,
      }

      const outputAudioMetadata = {
        samplingRate:
          config.audioDeviceConfig.outputSamplingRate ||
          this.audioContext.sampleRate,
        audioEncoding: 'linear16' as AudioEncoding,
      }

      console.log('Access to microphone granted', micSettings)

      if (!options.resume && options.initialAudioURL) {
        const resp = await fetch(options.initialAudioURL)
        const buffer = await resp.arrayBuffer()
        this.queueAudio(buffer)
      }

      if (this.config.enableDoneButton) {
        this.runOnConnect = () => {
          this.setDeferResponding(true)
        }
      }

      const startMessage = getAudioConfigStartMessage(
        {
          ...config,
          metadata: { ...config.metadata, resume: options.resume },
        },
        inputAudioMetadata,
        outputAudioMetadata
      )

      this.socket.send(stringify(startMessage))
      console.log(startMessage)

      this.recorder = new Recorder(this.micStream, this.socket)
      this.recorder.start()

      if (localStorage.enableVAD) {
        await this.setupVAD()
      }

      return true
    } catch (error: unknown) {
      console.error(error)
      this.stop(error as Error)
      return false
    }
  }

  async setupVAD() {
    let reallySpeaking = false
    let vadTimeout: NodeJS.Timeout

    const setPaused = (paused: boolean) => {
      this.paused = paused
      if (!this.paused) this.processQueue()
    }

    // @ts-expect-error -- vad is added by a script tag in index.html
    this.vad = await vad.MicVAD.new({
      onSpeechStart: () => {
        console.log('speechStart')
        vadTimeout = setTimeout(() => {
          this.playingBuffer?.stop()
          setPaused(true)
          reallySpeaking = true
        }, VAD_REALLY_SPEAKING_DELAY_MS)
      },
      onVADMisfire: () => {
        setPaused(false)
      },
      onSpeechEnd: () => {
        clearTimeout(vadTimeout)
        if (reallySpeaking) {
          console.log('speechEnd')
          this.audioQueue = []
          setPaused(false)
          markTime('speechEnd')
        }
        reallySpeaking = false
      },
    })
    this.vad.start()
  }

  setStatus(status: ConversationStatus) {
    this.status = status
    if (this.statusCallback) this.statusCallback(status)
  }

  setThinking(thinking: boolean) {
    this.thinking = thinking
    if (this.thinkingCallback) this.thinkingCallback(thinking)
  }

  setTalking(talking: boolean) {
    if (!this.talkingCallback) return

    if (this.delayListeningTimeout) {
      clearTimeout(this.delayListeningTimeout)
      this.delayListeningTimeout = undefined
    }
    if (talking) return this.talkingCallback(true)

    // when the response from the bot has multiple sentences and one of them is
    // short, there can be a "gap" in the middle of talking, when that short
    // sentence has been spoken, but audio for the next sentence has not yet
    // arrived.
    //
    // we add a delay here to avoid momentarily switching back to listening mode
    // during that gap.
    //
    // it's only roughly effective, because the length of the gap depends on the
    // length of the next sentence, and we don't want to have such a long gap
    // that it noticeably delays listening once the bot is really done speaking.
    this.delayListeningTimeout = setTimeout(() => {
      this.talkingCallback!(false)
      this.setDeferResponding(true)
      this.delayListeningTimeout = undefined
    }, DELAY_LISTENING_MS)
  }

  setError(error?: { message: string }) {
    if (this.errorCallback) this.errorCallback(error)
  }

  stop(error?: Error) {
    // Stop all active processes and clear the audio queue
    this.setThinking(false)
    this.playingBuffer?.stop()
    this.audioQueue = []

    // Handle error if one is passed
    if (error) {
      this.setError(error)
    }

    // Update status to 'idle'
    this.setStatus('idle')

    // Stop and clean up the recorder
    this.recorder?.stop()
    this.recorder = undefined

    // Stop and clean up the microphone stream
    this.vad?.pause()
    this.micStream?.getTracks().forEach(track => track.stop())
    this.micStream = undefined // Clear the micStream reference

    // Close the WebSocket connection if open and remove event listeners
    if (this.socket) {
      if (this.socket.readyState === WebSocket.OPEN) {
        const stopMessage: StopMessage = { type: 'websocket_stop' }
        this.socket.send(stringify(stopMessage))
        this.socket.close()
      }
      this.socket.removeEventListener('message', this.onMessageHandler)
      this.socket.removeEventListener('close', this.onCloseHandler)
      this.socket = undefined // Clear the socket reference
    }

    // KPH: AudioContext closure - currently causes issues with the microphone on re-start
    // if (this.audioContext) {
    //   this.audioContext.close();
    // }

    // Clear the singleton reference to allow garbage collection
    singleton = undefined
  }

  onMessageHandler = (event: MessageEvent) => {
    const message = JSON.parse(event.data)
    switch (message.type) {
      case 'websocket_ready':
        this.setStatus('connected')
        if (this.runOnConnect) {
          this.runOnConnect()
          this.runOnConnect = undefined
        }
        break
      case 'websocket_audio':
        if (message.data.length === 0) return
        markTime('response', this.config.backendUrl)
        this.setThinking(false)
        this.setTalking(true)
        this.queueAudio(Buffer.from(message.data, 'base64'))
        if (this.responseFailureTimeout) {
          clearTimeout(this.responseFailureTimeout)
          this.responseFailureTimeout = undefined
        }
        break
      case 'websocket_transcript':
        if (this.transcriptCallback) {
          if (message.sender === 'bot' && message.text.trim() != '-') {
            this.transcriptCallback(message.text)
          }
        }
        break
      case 'bot_thinking':
        if (!this.thinking) markTime('transcription', this.config.backendUrl)
        this.setThinking(true)
        break
      case 'bot_not_thinking':
        this.setThinking(false)
        break
      default:
        if (this.socketMessageCallback) this.socketMessageCallback(message)
        break
    }
  }

  onCloseHandler = (event: CloseEvent) => {
    console.log("onCloseHandler", event)
    if (this.status != 'idle' && event.code != 1000) {
      let message = event.reason || 'Disconnected'
      if (event.reason === 'Unauthorized') {
        message = 'Please reload the page and sign in again.'
      }
      this.stop(new Error(message))
    }
  }

  interrupt() {
    if (!this.socket || this.status !== 'connected') return false
    this.sendControlMessage('interrupt', { val: 1 })
  }

  sendControlMessage(
    op: ControlMessageOp,
    args: { val: number; str?: string }
  ): boolean {
    if (!this.socket || this.status !== 'connected') return false
    this.socket.send(stringify({ type: 'websocket_control', op, ...args }))
    return true
  }

  queueAudio(buffer: ArrayBuffer) {
    this.audioQueue.push(buffer)
    this.processQueue()
  }

  // Controls the MediaRecorder based on the audio queue state
  controlMediaRecorder(isQueueNotEmpty: boolean) {
    if (isQueueNotEmpty && this.recorder?.state === 'recording') {
      this.recorder.pause()
    } else if (!isQueueNotEmpty && this.recorder?.state === 'paused') {
      this.recorder.resume()
    }
  }

  // Handles the overall state based on the audio queue
  handleQueueState() {
    const isQueueNotEmpty = this.audioQueue.length !== 0
    this.setTalking(isQueueNotEmpty)
    // FIXME disabling this for now because it's buggy
    // this.controlMediaRecorder(isQueueNotEmpty)
  }

  async processQueue(isRecursive = false) {
    this.handleQueueState()

    // If already processing and this is not a recursive call, exit
    if (this.processing && !isRecursive) {
      return
    }

    // If the queue is empty, reset the processing flag and exit
    if (this.audioQueue.length === 0) {
      this.processing = false
      return
    }

    // Set the processing flag
    this.processing = true

    // Dequeue the first audio item
    const audio = this.audioQueue[0]

    try {
      // Fetch and play the audio buffer
      const response = await fetch(URL.createObjectURL(new Blob([audio])))
      const arrayBuffer = await response.arrayBuffer()
      await this.playBuffer(arrayBuffer)

      // Remove the processed audio item from the queue
      this.audioQueue.shift()
    } catch (error) {
      console.error('An error occurred while processing the audio:', error)
      return // Stop processing the rest of the queue
    } finally {
      // Reset the processing flag only if the queue is empty
      if (this.audioQueue.length === 0) {
        this.processing = false
      }
    }

    // Continue processing the queue
    this.processQueue(true)
  }

  setMuteMic(mute: boolean) {
    if (!MUTE_MIC_WHEN_SPEAKING) return

    if (this.unmuteTimeout) {
      clearTimeout(this.unmuteTimeout)
      this.unmuteTimeout = undefined
    }

    if (this.micStream && this.muteMic != mute) {
      this.micStream.getAudioTracks().forEach(track => {
        track.enabled = !mute
      })
      this.muteMic = mute
    }
  }

  setDeferResponding(defer: boolean) {
    if (this.config.enableDoneButton) {
      this.sendControlMessage('defer_responding', { val: defer ? 1 : 0 })
    }
  }

  debounceUnmuteMic() {
    if (this.unmuteTimeout) {
      clearTimeout(this.unmuteTimeout)
      this.unmuteTimeout = undefined
    }

    this.unmuteTimeout = setTimeout(() => {
      if (this.audioQueue.length === 0) {
        this.setMuteMic(false)
      }
    }, DELAY_UNMUTE_MS)
  }

  async playBuffer(arrayBuffer: ArrayBuffer) {
    const buffer = await this.audioContext.decodeAudioData(arrayBuffer)
    return this.playAudioData(buffer)
  }

  async playAudioData(audioBuffer: AudioBuffer) {
    return new Promise(async resolve => {
      try {
        this.setMuteMic(true)
        const source = this.audioContext.createBufferSource()
        source.buffer = audioBuffer
        source.connect(this.audioContext.destination)
        source.connect(this.analyser)
        source.start(0)
        this.playingBuffer = source
        source.onended = () => {
          this.debounceUnmuteMic()
          resolve(null)
        }
      } catch (err) {
        console.error(err)
        this.debounceUnmuteMic() // Unmute if there's an error
        resolve(null)
      }
    })
  }

  async playSoundEffect(name: string) {
    if (!this.soundEffects || !this.soundEffects[name]) return
    return this.playAudioData(this.soundEffects[name])
  }

  testSpeakNow() {
    this.sendControlMessage('test', { val: 0, str: "speak_now" })
  }

  startTimingResponse() {
    this.responseFailureTimeout = setTimeout(() => {
      console.log('Timed out while waiting for a response')
    }, 10000)
  }
}
