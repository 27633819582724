import type { AudioEncoding, AudioConfigStartMessage } from 'vocode'


// this wrapper is necessary because `new WebSocket()` may throw errors that
// can't be caught normally
export const createWebSocket = async (
  url: string,
  timeout: number = 15
): Promise<WebSocket> =>
  new Promise((resolve, reject) => {
    const socket = new WebSocket(url)

    const wait = setTimeout(
      () => reject(new Error("Couldn't connect (timed out)")),
      timeout * 1000
    )

    socket.onopen = () => {
      clearTimeout(wait)
      resolve(socket)
    }
    socket.onerror = event => {
      clearTimeout(wait)
      const error = new Error("Couldn't connect")
      ;(error as any).event = event
      reject(error)
    }
    return socket
  })

export const VAD_REALLY_SPEAKING_DELAY_MS = 500

const latencyTimerLog: any[] = []

export const markTime = (name: string, host?: string) => {
  if (!localStorage.enableVAD) return

  const now = new Date().getTime()
  const last = latencyTimerLog[latencyTimerLog.length - 1]
  if (name !== 'speechEnd' && last?.name == name) return

  latencyTimerLog.push({ name, time: now })

  if (host && last) {
    if (last.name === 'response') return

    const ms = now - last.time
    console.log(`timer: ${last.name} -> ${name}: ${ms} ms`)
  }
}

const DEFAULT_CHUNK_SIZE = 2048

export const getAudioConfigStartMessage = (
  config: {
    chunkSize?: number | undefined
    downsampling?: number | undefined
    conversationId?: string | undefined
    subscribeTranscript?: boolean | undefined
    metadata: any
  },
  inputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding },
  outputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding }
): AudioConfigStartMessage => ({
  type: 'websocket_audio_config_start',
  inputAudioConfig: {
    samplingRate: inputAudioMetadata.samplingRate,
    audioEncoding: inputAudioMetadata.audioEncoding,
    chunkSize: config.chunkSize || DEFAULT_CHUNK_SIZE,
    downsampling: config.downsampling,
  },
  outputAudioConfig: {
    samplingRate: outputAudioMetadata.samplingRate,
    audioEncoding: outputAudioMetadata.audioEncoding,
  },
  conversationId: config.conversationId,
  subscribeTranscript: config.subscribeTranscript,
    // @ts-expect-error
  metadata: config.metadata,
})





