import round from 'lodash/round'
import { useEffect, useRef, useState } from 'react'
// import { analytics } from '../analytics'

const TIMER_LOOP_MS = 1000

// weird mix of state variables here.
//
// we have useState, for variables that we want to trigger re-running useEffect
// and re-defining the interval function.
//
// we have a ref, for a variable whose current value we need to read from inside
// the interval function, and change from outside it, without re-running
// useEffect.
//
// and we have local counter variables that are defined inside useEffect,
// because they don't need to be read at all from outside.
export default function useTimer(verbose?: boolean) {
  const [ended, setEnded] = useState(false)
  const [startTime, setStartTime] = useState<number>()
  const paused = useRef(false)

  useEffect(() => {
    if (!startTime || ended) return

    let activeTime = 0,
      totalTime = 0,
      lastCheckTimestamp = startTime

    const interval = setInterval(() => {
      const now = Date.now()
      totalTime = now - startTime

      if (!paused.current) {
        activeTime = activeTime + now - lastCheckTimestamp

        if (activeTime % 60_000 < TIMER_LOOP_MS) {
          const minutes = Math.round(activeTime / 60_000)
          const totalMinutes = round(totalTime / 60_000, 1)
          // analytics.track('Conversation Lasted', { minutes, totalMinutes })
          if (verbose)
            console.log(
              `Conversation lasted ${minutes} minutes (${totalMinutes} total)`
            )
        }
      }

      lastCheckTimestamp = now

      if (verbose)
        console.log({ paused: paused.current, totalTime, activeTime })
    }, TIMER_LOOP_MS)

    return () => clearInterval(interval)
  }, [startTime, ended])

  return {
    startTimer: () => {
      if (!startTime) {
        const now = Date.now()
        setStartTime(now)
        if (verbose) console.log(`started timer: ${now}`)
      }
      paused.current = false
    },
    pauseTimer: () => {
      if (verbose) console.log('paused timer')
      paused.current = true
    },
    stopTimer: () => setEnded(true),
  }
}
